import React from "react";
import { ThemeProvider as MUIThemeProvider } from "@material-ui/core";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

const ThemeProvider = ({ theme, children }) => (
  <MUIThemeProvider theme={theme}>
    <StyledThemeProvider theme={theme}>
      {children}
    </StyledThemeProvider>
  </MUIThemeProvider>
);


export default ThemeProvider;