import { useContext } from "react";
import { navigate } from "gatsby";
import IntlContext from "./intl-context";

export const useIntl = () => {
  const intl = useContext(IntlContext);
  return {
    ...intl,
    formatMessage: ({ id }) => intl.messages[id],
    getPagePath: id => {
      return intl.allPageLinks[id] ? intl.allPageLinks[id][intl.locale] : `${id}`;
    },
    changeLocale: localeId => {
      if (typeof window !== "undefined") {
        window.localStorage.setItem("gatsby__intl-locale", localeId);
      }
      navigate(intl.currentPageLinks[localeId])
    }
  };
};

export default useIntl;