/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { CssBaseline } from "@material-ui/core"
import PageContext from "../util/page-context";
import SEO from "../components/seo";
import CookieConsent from "../components/cookie-consent/cookie-consent"

import ThemeProvider from "../theme-provider"
import theme from "../theme"

const BasePage = ({ pageContext, children }) => {
  return (
    <PageContext.Provider value={pageContext}>
      <ThemeProvider theme={theme}>
        <SEO />
        <CssBaseline />
        <CookieConsent>{children}</CookieConsent>
      </ThemeProvider>
    </PageContext.Provider>
  )
}

BasePage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BasePage
