import React, { useState } from "react"
import styled from "styled-components"
import { useTheme } from "@material-ui/core/styles"
import LanguageSelect from "./language-select"
import { Link } from "gatsby";
import { useIntl } from "../intl"

import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import Brand from "./brand"

const StyledAppBar = styled(AppBar)`
  background-color: ${props => props.theme.palette.dark.main};
  transition: background-color 0.2s ease;
  box-shadow: 0px 0px 50px 0px rgb(214, 214, 214);
  color: ${props => props.theme.palette.dark.contrastText};
`

const BrandContainer = styled.div`
  flex: 1;
`

const StyledList = styled(List)`
  width: 100px;
`

const StyledLink = styled(Link)`
  font-weight: ${props => (props.active ? "700" : "inherit")};
  color: ${props => props.theme.palette.dark.contrastText};
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
  :not(:last-child) {
    padding-right: 1.5em;
  }
`

const StyledMobileLink = styled(StyledLink)`
  color: inherit;
`;

const links = [
  { id: "home", to: "home" },
  { id: "demo", to: "demo" },
  { id: "about", to: "about" },
  { id: "faq", to: "faq", partiallyActive: true },
  { id: "contact", to: "contact" },
];

const Links = () => {
  const intl = useIntl()
  return (
    <>
      {links.map(link => (
        <StyledLink key={link.id} to={intl.getPagePath(link.to)} activeStyle={{ fontWeight: 700 }} partiallyActive={link.partiallyActive}
>
          {intl.formatMessage({ id: `link_${link.id}_text` })}
        </StyledLink>
      ))}
      <LanguageSelect />
    </>
  )
}

const MobileLink = ({link, ...props}) => {
  const intl = useIntl();
  return (
    <ListItem button>
      <StyledMobileLink to={intl.getPagePath(link.to)} {...props}>
        {intl.formatMessage({ id: `link_${link.id}_text` })}
      </StyledMobileLink>
    </ListItem>
  );
}

const MobileLinks = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="menu"
        onClick={() => setMenuOpen(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={menuOpen} onClose={() => setMenuOpen(false)}>
        <StyledList>
          {links.map(link => (
            <MobileLink key={link.id} link={link} activeStyle={{ fontWeight: 700 }} partiallyActive={link.partiallyActive} />
          ))}
          <ListItem>
            <LanguageSelect />
          </ListItem>
        </StyledList>
      </Drawer>
    </>
  )
}

const Navigation = ({ className }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <StyledAppBar position="static" className={className}>
      <Toolbar>
        <BrandContainer>
          <Brand />
        </BrandContainer>
        {matches ? <Links /> : <MobileLinks />}
      </Toolbar>
    </StyledAppBar>
  )
}

export default Navigation
