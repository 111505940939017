import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import React from "react";

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "insync-logo.png" }) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Img
      fixed={data.file.childImageSharp.fixed}
      alt="inSync logo"
    />
  )
}

export default Logo;