import React from "react";
import Logo from "./logo";
import styled from "styled-components";
import { Box } from "@material-ui/core";

const BrandTitle = styled.span`
  font-weight: bold;
  padding: 5px 0 0 5px;
`;

const Brand = () => {
  return (
    <Box display="flex" alignItems="center">
      <Logo />
      <BrandTitle>Stageplayer</BrandTitle>
    </Box>
  );
};

export default Brand;