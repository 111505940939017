import React from "react"
import Helmet from "react-helmet"
import { sprintf } from "sprintf-js"
import { useIntl } from "../intl"
import { usePageContext } from "../util/page-context";

const SEO = () => {
  const intl = useIntl();
  const pageContext = usePageContext();
  return (
    <Helmet>
      <html lang={intl.locale} />
      <title lang={intl.locale}>
        {sprintf(intl.formatMessage({ id: "title_template" }), [pageContext.metadata.title])}
      </title>
    </Helmet>
  )
}

export default SEO
