import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Titillium Web"
    ].join(','),
  },
  palette: {
    primary: {
      main: '#014389',
    },
    secondary: {
      main: '#FB7000',
    },
    dark: {
      main: "#000d23",
      contrastText: '#fff',
    },
    text: {
      primary: "#5E5E5E"
    },
    background: {
      default: "#FBFBFB",
      paper: "#F6F6F6"
    },
    footer: {
      main: "#F1F1F1"
    }
  },
});

export default responsiveFontSizes(theme);