import React, { useEffect, useCallback } from "react"
import { Snackbar, Button } from "@material-ui/core"
import Cookies from "js-cookie";
import { useIntl } from "../../intl";
import CookieConsentContext from "./cookie-consent-context";
import { COOKIE_CONSENT_COOKIE } from "../../constants";

const CookieConsent = ({ children }) => {
  const intl = useIntl();
  const [didConsent, setDidConsent] = React.useState(false);

  useEffect(() => {
    const didConsentCookie = Cookies.get(COOKIE_CONSENT_COOKIE);
    setDidConsent(!!didConsentCookie);
  }, []);

  const handleClick = useCallback(() => {
    Cookies.set(COOKIE_CONSENT_COOKIE, 'true');
    setDidConsent(true);
  }, []);

  const handleClose = (event, reason) => {
    setDidConsent(false);
  };

  return (
    <>
      <CookieConsentContext.Provider value={didConsent}>
        {children}
      </CookieConsentContext.Provider>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!didConsent}
        onClose={handleClose}
        message={intl.formatMessage({ id: "cookie_bar_msg" })}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClick}>
              {intl.formatMessage({ id: "cookie_bar_accept_msg" })}
            </Button>
          </React.Fragment>
        }
      />
    </>
  );
}

export default CookieConsent